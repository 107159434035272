
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Rate, Icon } from "vant";
@Component({
  components: {
    [Rate.name]: Rate,
    [Icon.name]: Icon,
  },
})
export default class HotelMap extends Vue {
  // @Inject({ default: () => [] }) getHotelListData;

  // get hotelList() {
  //   return this.getHotelListData();
  // }
  @Prop({ type: Array, default: () => [] }) hotelList;
  @Prop({ type: Number, default: 1 }) pageIndex;

  @Watch("hotelList", { deep: true, immediate: true }) watchData(newVal) {
    if (newVal.length > 0) {
      // this.hotelIndex = 0;
      this.newMapLabel();
      this.setCenter();
    } else {
      this.map.remove(this.markerList);
      this.markerList = [];
    }
  }

  public map = null;
  mounted() {
    this.getMapData();
  }

  onMyLocation() {
    this.$toast.loading({
      message: "定位中...",
      loadingType: "spinner",
      duration: 0,
      className: "zIndex9999",
    });
    let that = this;
    AMap.plugin("AMap.Geolocation", function () {
      var geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, // 是否使用高精度定位，默认：true
        timeout: 10000, // 设置定位超时时间，默认：无穷大
        offset: [10, 20], // 定位按钮的停靠位置的偏移量
        zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        position: "RB", //  定位按钮的排放位置,  RB表示右下
        markerOptions: {
          //自定义定位点样式，同Marker的Options
          offset: new AMap.Pixel(0, -2),
          // content: `<img src="${require('@src/hotel/assets/img/facilities_map_icon.png')}" style="width:23px;height:23px"/>`
          icon: require("@/assets/image/my_location_icon.png"),
        },
      });
      geolocation.getCurrentPosition(async (status, result) => {
        if (status == "complete") {
          //精确定位成功
          const {
            position: { KL, kT },
          } = result;
          that.$emit("searchData", {
            Lon: KL,
            Lat: kT,
          });
          that.$toast.clear();
        } else {
          geolocation.getCityInfo((status, result) => {
            if (status == "complete") {
              that.$emit("searchData", {
                Lon: result.position[0],
                Lat: result.position[1],
              });
              that.$toast.clear();
            } else that.$toast("定位失败");
          });
          that.map.addControl(geolocation);
        }
      });
      that.map.addControl(geolocation);
    });
  }

  public isSearchArea: boolean = false;
  public areaCoordinates = { lon: "", lat: "" };
  getMapData() {
    let that = this;
    that.map = new AMap.Map("container", {
      viewMode: "2D", // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D'
      zoom: 15, // 初始化地图层级
    });
    that.newMapLabel();

    that.map.on("click", (e) => {
      if (that.areaMarker) {
        that.map.remove(that.areaMarker);
      }
      that.areaMarker = new AMap.Marker({
        position: new AMap.LngLat(e.lnglat.getLng(), e.lnglat.getLat()),
        icon: require("@/assets/image/my_location_icon.png"), // 添加 Icon 图标 URL
        offset: new AMap.Pixel(-13, -30),
      });
      that.areaMarker.setLabel({
        direction: "top",
        offset: new AMap.Pixel(0, -2), // 设置文本标注偏移量
        content: `<div id='label_div' style='background: #008ffe; color: #fff; border: none; padding: 8px 12px; border-radius: 10px;'>附近酒店</div>`,
      });
      that.areaMarker.on("click", (e) => {
        that.onSearchArea();
      });
      that.areaMarker.setMap(that.map);
      that.map.setCenter([e.lnglat.getLng(), e.lnglat.getLat()]);
      that.isSearchArea = true;
      that.areaCoordinates.lon = e.lnglat.getLng();
      that.areaCoordinates.lat = e.lnglat.getLat();
    });
  }
  public markerList = [];
  public newMapLabel() {
    if (this.map) {
      this.map.remove(this.markerList);
      this.markerList = [];

      this.hotelList &&
        this.hotelList.forEach((item, index) => {
          let marker = new AMap.Marker({
            position: new AMap.LngLat(item.GDLongitude, item.GDLatitude),
            icon: require("@/assets/image/facilities_map_icon.png"), // 添加 Icon 图标 URL
            // topWhenClick: true // 鼠标点击时marker是否置顶，默认false
          });

          // 设置文本标签;
          marker.setLabel({
            direction: "top",
            offset: new AMap.Pixel(0, -2), // 设置文本标注偏移量
            content: `
          <div class='map_info ${
            index === this.hotelIndex ? "infoActive" : ""
          }' id='label_div'>
            <div class='info_left'>${item.HotelName}</div>
            <div class='info_center'></div>
            <div class='info_right'>
              <div class='price'>￥${item.MinPrice}</div>
              <img src='${
                index === this.hotelIndex
                  ? require("@/assets/image/enter_icon2.png")
                  : require("@/assets/image/enter_icon1.png")
              }' />
            </div>
          </div>`,
          });
          marker.on("click", () => {
            this.onChooseHotelItem(index);
            marker.setIcon(
              `${require("@/assets/image/facilities_map_icon.png")}`
            );
            this.setCenter();
          });
          this.markerList.push(marker);
        });
      if (this.markerList.length > 0) {
        this.setCenter();
        this.map.add(this.markerList);
      }
    }
  }
  // 选择位置
  public hotelIndex = null;
  public areaMarker = null;
  public onChooseHotelItem(index) {
    if (this.hotelIndex !== index) {
      this.hotelIndex = index;
    }
    if (this.areaMarker) {
      this.map.remove(this.areaMarker);
      this.isSearchArea = false;
    }
  }

  // 设置中心点
  public setCenter() {
    if (!this.hotelIndex) this.hotelIndex = 0;
    if (this.map) {
      this.map.setCenter([
        this.hotelList[this.hotelIndex].GDLongitude,
        this.hotelList[this.hotelIndex].GDLatitude,
      ]);
      if (this.markerList.length > 0) {
        this.markerList.forEach((item, index) => {
          item.setIcon(`${require("@/assets/image/facilities_map_icon.png")}`);
        });
        this.markerList[this.hotelIndex].setIcon(
          `${require("@/assets/image/facilities_active_map_icon.png")}`
        );
      }
      this.map.panBy(0, -110); //设置地图平移
      this.onChooseHotelItem(this.hotelIndex);
      let mapBody = document.getElementById("container");
      let box = mapBody.querySelectorAll(".map_info");
      if (box.length == 0) {
        let markers = mapBody.querySelectorAll(".amap-markers")[0];
        this.$nextTick(() => {
          let marker = markers.querySelectorAll(".amap-marker")[0];
          marker.className = "amap-marker amap-marker-zIndex";
        });
      }

      for (let i = 0; i < box.length; i++) {
        (box[i] as HTMLElement).className = "map_info";
        if ((box[i] as HTMLElement).querySelector("img"))
          (
            (box[i] as HTMLElement).querySelector("img") as any
          ).src = require("@/assets/image/enter_icon1.png");
        (box[i].parentNode.parentNode as HTMLElement).className = "amap-marker";

        if (i === this.hotelIndex) {
          (box[this.hotelIndex] as HTMLElement).className =
            "map_info infoActive";
          if ((box[this.hotelIndex] as HTMLElement).querySelector("img"))
            (
              (box[this.hotelIndex] as HTMLElement).querySelector("img") as any
            ).src = require("@/assets/image/enter_icon2.png");
          (
            box[this.hotelIndex].parentNode.parentNode as HTMLElement
          ).className = "amap-marker amap-marker-zIndex";
        }
      }
    }
  }

  public async onSearchArea() {
    this.$emit("searchData", {
      Lon: this.areaCoordinates.lon,
      Lat: this.areaCoordinates.lat,
    });
  }

  public tabLeft() {
    this.hotelIndex--;
    this.setCenter();
  }
  public tabRight() {
    this.hotelIndex++;
    this.setCenter();
  }
  public loadMore() {
    let num = +this.pageIndex;
    num += 1;
    this.$emit("pageIndex", num);
  }

  public toHotelDetailPage(HotelCode) {
    this.$router.push({
      path: "/hotelDetail",
      query: {
        HotelCode: HotelCode,
      },
    });
  }
}
