import md5 from "js-md5";
export function signatureGenerate({ id, prepayId }) {
    // 应用ID +时间戳+随机字符串+订单详情扩展字符串
    // 随机字符串
    let secret = Math.random().toString(36).substring(2, 40 + 2);
    // 时间戳
    let timestamp = new Date().getTime()
    // 生成签名
    let str = 'appId\n=' + id + "timestamp\n=" + timestamp + "&secret\n=" + secret + "&prepay_id\n=" + prepayId

    const sign = md5(str)

    return sign
}


// 防抖
export const debounce = (fn, timeout) => {
    let timer = null
    return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(window, arguments)
        }, timeout);
    }
}
