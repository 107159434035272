
class HotelFilterType {
  CustomerSource?: string;
  FilterBed?: [];
  FilterBrand?: [any];
  FilterBreak?: [];
  FilterCancel?: [];
  FilterCountyID?: string;
  FilterPosition?: string;
  FilterStar?: [];
  FilterWindows?: [];
  PriceFrom?: string;
  PriceTo?: string;
  OrderBy?: string;
  PageIndex?: string | number;
  Lon?: string;
  Lat?: string;
}

import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import {
  NavBar,
  Icon,
  Search,
  DropdownMenu,
  DropdownItem,
  Cell,
  TreeSelect,
  Button,
  Slider,
  Badge,
  Divider,
  Loading,
  List,
  Rate,
  Empty,
  Image as VanImage,
} from "vant";

import { fectHotelListApi, fectLocationApi } from "@/api/hotel/index.api";

import SelectDate from "@/components/selectDate/index.vue";
import CitySelect from "@/components/citySelect/index.vue";

import HotelMap from "@/components/hotelMap/index.vue";

import { debounce } from "@/utils/utils";

@Component({
  components: {
    SelectDate,
    CitySelect,
    HotelMap,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Cell.name]: Cell,
    [TreeSelect.name]: TreeSelect,
    [Button.name]: Button,
    [Slider.name]: Slider,
    [Badge.name]: Badge,
    [Divider.name]: Divider,
    [Loading.name]: Loading,
    [List.name]: List,
    [Rate.name]: Rate,
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
  },
})
export default class HotelList extends Vue {
  public isLoading: boolean = false;

  public getChangeDate(date) {
    return this.$moment(date).format("MM月DD日");
  }

  public closed(val, key, type) {
    console.log(val);
    console.log(key);

    if (type == "obj") {
      this.$delete(this.queryHotelListCon, key);
    } else
      this.queryHotelListCon[key].splice(
        this.queryHotelListCon[key].indexOf(val),
        1
      );

    this.hotelFilterData[key] = this.queryHotelListCon[key] || "";

    if (key == "PRICE") {
      this.hotelFilterData["PriceFrom"] = "";
      this.hotelFilterData["PriceTo"] = "";
      this.priceRange = [0, 1000];
    }
    if (key == "FilterStar") {
      this.starList.forEach((item) => {
        let index =
          this.queryHotelListCon[key] &&
          this.queryHotelListCon[key].findIndex((ele) => ele.code == item.code);
        if (index == -1) this.$set(item, "checked", false);
      });
      this.hotelFilterData[key] = this.queryHotelListCon[key]
        .map((item) => item.code)
        .filter(Boolean);
    }
    if (key == "FilterBrand") this.getBrandData();

    this._getHotelList();
  }

  public hotelData = {
    Action: "QueryHotel",

    CityCode: "C1025",
    CityName: "北京",
    KeyWord: "",
    BeginDate: this.$moment().format("yyyy-MM-DD"),
    EndDate: this.$moment().add(1, "days").format("yyyy-MM-DD"),
  };

  // public hotelFilterData = {
  //   FilterBed: [],
  //   FilterBrand: [],
  //   FilterBreak: [],
  //   FilterCancel: [],
  //   FilterCountyID: '',
  //   FilterPosition: '',
  //   FilterStar: [],
  //   FilterWindows: [],
  //   PriceFrom: '',
  //   PriceTo: '',
  //   OrderBy: ''
  // };
  public hotelFilterData = {
    ...new HotelFilterType(),
    PageIndex: 1,
    FilterBrand: [],
  };

  public queryHotelListCon = {};

  public showDate: boolean = false;
  public isShowCity: boolean = false;
  public handleConfirm(date) {
    this.hotelData.BeginDate = date.star;
    this.hotelData.EndDate = date.end;
    this._getHotelList();
  }
  public getCityData(val) {
    // if(this.hotelData.CityCode == val.CityCode) return
    this.hotelData.CityName = val.name;
    this.hotelData.CityCode = val.CityCode;
    this.hotelData.KeyWord = val.County || "";
    this.hotelFilterData = {
      ...new HotelFilterType(),
      PageIndex: 1,
      FilterBrand: [],
    };
    this.queryHotelListCon = {};
    this.getBrandData();
    this._getHotelList();
    this.getLocationItem();
    this.$set(this, "priceRange", [0, 1000]);
    this.starList.forEach((ele) => {
      this.$set(ele, "checked", false);
    });
  }

  public handleGetKeyWord = debounce((val) => {
    this.hotelData.KeyWord = val;
    this._getHotelList()
  }, 1000)

  public hotelListData = [];
  public finished: boolean = false;
  public onLoad() {
    this.hotelFilterData.PageIndex = ++this.hotelFilterData.PageIndex;

    this.getHotelList(this.hotelFilterData);

    // // 数据全部加载完成
    // if (this.list.length >= 40) {
    //   this.finished = true;
    // }
  }
  public erwaiStuats: boolean = false;
  public queryHotelListReq = {};
  public _getHotelList() {
    this.hotelListData = [];
    this.hotelFilterData.PageIndex = 1;
    this.$nextTick(() => {
      if (!this.isShowMap) document.getElementById("hotel_list").scrollTop = 0;
    });
    this.getHotelList(this.hotelFilterData);
  }
  public async getHotelList(queryData) {
    try {
      this.$toast.loading({
        message: "搜索中...",
        loadingType: "spinner",
        duration: 0,
        className: "zIndex9999",
      });
      this.isLoading = true;
      this.saveQueryHotelData();
      let param = Object.assign(
        {},
        JSON.parse(JSON.stringify(queryData)),
        this.hotelData
      );
      let { data } = await fectHotelListApi(param);

      let hotelData = JSON.parse(data.ReturnJson);
      console.log(hotelData);
      this.queryHotelListReq = hotelData;

      if (hotelData && hotelData.ResponseResult) {
        if (param.PageIndex == 1) {
          this.$set(this, "hotelListData", hotelData.ResponseResult);
        } else {
          this.$set(
            this,
            "hotelListData",
            this.hotelListData.concat(hotelData.ResponseResult)
          );
        }
        this.hotelListData.forEach((item) => {
          this.$set(item, "HotelLevel", +item.HotelLevel);
        });

        // if (this.erwaiStuats)
        console.log("hotelListData--", this.hotelListData);
        this.isLoading = false;

        if (hotelData.PageNumber >= hotelData.TotalPage) {
          console.log(111);
          this.finished = true;
        }

        this.$nextTick(() => {
          if (this.hotelListData.length == 0) {
            this.$toast("未找到对应条件的酒店");
            this.erwaiStuats = true;
          }
        });
      }
    } finally {
      this.isLoading = false;
      this.$toast.clear();
    }
  }

  // @Provide("getHotelListData") getHotelListData = this.getData;
  // getData() {
  //   return this.hotelListData;
  // }

  public saveQueryHotelData() {
    sessionStorage.setItem("queryHotelData", JSON.stringify(this.hotelData));
  }

  public sortList = [
    { code: null, title: "推荐排序", label: "根据点评分、热门综合排序" },
    { code: "101", title: "价格（由低到高）" },
    { code: "102", title: "价格（由高到低）" },
  ];
  public handleGetSort(item) {
    if (!item) return;
    this.$set(this.hotelFilterData, "OrderBy", item.code);
    this.$set(this.queryHotelListCon, "OrderBy", item);
    this._getHotelList();
    (this.$refs.sortItem as any).toggle(false);
  }

  public typeListObj = [
    {
      text: "行政区",
      type: "regionList",
      typeCode: "Region",
    },
    {
      text: "地铁",
      type: "subwayList",
      typeCode: "Subway",
    },
    {
      text: "火车站",
      type: "stationList",
      typeCode: "Station",
    },
    {
      text: "下辖市县",
      type: "countyList",
      typeCode: "County",
    },
    {
      text: "展馆",
      type: "pavilionList",
      typeCode: "Pavilion",
    },
  ];

  public typeList = JSON.parse(JSON.stringify(this.typeListObj));
  public async getLocationItem() {
    try {
      let { data } = await fectLocationApi({
        Action: "SearchCityFilterData",
        CityCode: this.hotelData.CityCode,
      });
      let listConds = JSON.parse(data.ReturnJson);
      for (const key in listConds) {
        listConds[key] = listConds[key].map((item) => {
          return {
            ...item,
            text: item.Name || item.PositionName || item.Title,
          };
        });
      }

      this.typeListObj = this.typeList
        .map((item) => {
          if (listConds[item.typeCode].length > 0) {
            return {
              ...item,
              children: listConds[item.typeCode],
            };
          }
        })
        .filter(Boolean);
    } finally {
    }
  }
  public activeIndex = 0;
  public activeRegionIndex = 0;
  public activeSubwayIndex = 0;

  openRegionItem() {
    if (
      !this.hotelFilterData.FilterCountyID &&
      !this.hotelFilterData.FilterPosition
    ) {
      this.activeIndex = this.activeRegionIndex = this.activeSubwayIndex = 0;
    }
  }
  public handleActiveIndex(index) {
    this.activeIndex = index;
    this.activeRegionIndex = this.activeSubwayIndex = 0;
  }

  public getSubwayXian(index) {
    this.activeSubwayIndex = index;
  }
  public regionObj: any = "";
  public handleGetRegion(item, index) {
    if (!item) return;
    this.activeRegionIndex = index;
    this.regionObj = item;
  }

  public resetRegion() {
    this.activeIndex = this.activeSubwayIndex = this.activeRegionIndex = 0;
    this.regionObj = null;
    this.handleClickRegion();
  }
  public handleClickRegion() {
    let region = this.regionObj;
    this.$set(this.queryHotelListCon, "FilterCountyID", {
      code: (region && region.Code) || "",
      title: (region && region.Name) || "",
    });
    this.$set(this.queryHotelListCon, "FilterPosition", {
      code: (region && region.PositionID) || "",
      title: (region && region.PositionName) || "",
    });

    this.$set(
      this.hotelFilterData,
      "FilterCountyID",
      (region && region.Code) || ""
    );
    this.$set(
      this.hotelFilterData,
      "FilterPosition",
      (region && region.PositionID) || ""
    );
    this.hotelFilterData.Lon = "";
    this.hotelFilterData.Lat = "";
    this._getHotelList();
    (this.$refs.regionItem as any).toggle(false);
  }

  public priceRange = [0, 1000];
  public priceArr = [];
  @Watch("priceRange", { deep: true, immediate: true }) watchPriceData(newVal) {
    if (!newVal) return;
    if (newVal[0] == 0 && newVal[1] == 1000) {
      this.priceTitle = "";
      this.priceArr = [null, null];
    } else if (newVal[0] >= 1000 && newVal[1] == 1000) {
      this.priceTitle = "￥" + newVal[0] + "以上";
      this.priceArr = [1000, 99999];
    } else {
      this.priceTitle = "￥" + newVal[0] + "~" + newVal[1];
      this.priceArr = [newVal[0], newVal[1]];
    }
  }
  public isShowPriceTitle: boolean = false;
  public priceList = [
    { title: "不限", code: [0, 1000] },
    { title: "200以下", code: [0, 200] },
    { title: "200-299", code: [200, 299] },
    { title: "300-399", code: [300, 399] },
    { title: "400-499", code: [400, 499] },
    { title: "500-999", code: [500, 999] },
    { title: "1000元以上", code: [1000, 1000] },
  ];
  public priceTitle = "";
  public getPriceBtnData(item) {
    this.$set(this, "priceRange", JSON.parse(JSON.stringify(item.code)));
  }
  public starList: any = [
    { title: "经济型", code: "1" },
    { title: "三星/商务", code: "2" },
    { title: "四星/高档", code: "3" },
    { title: "五星/豪华", code: "4" },
  ];
  public getStarBtnData(item) {
    if (!item) {
      this.starList.forEach((ele) => {
        this.$set(ele, "checked", false);
      });
      this.hotelFilterData.FilterStar = [];
      return;
    }
    this.$set(item, "checked", !item.checked);
  }
  public resetPriceStar() {
    this.priceTitle = "";
    this.priceArr = [null, null];
    this.$set(this, "priceRange", [0, 1000]);
    this.starList.forEach((ele) => {
      this.$set(ele, "checked", false);
    });
    this.savePriceStarDate();
  }

  public savePriceStarDate() {
    this.queryHotelListCon = {
      ...this.queryHotelListCon,
      PRICE: {
        title: this.priceTitle,
      },
    };
    this.$set(
      this.queryHotelListCon,
      "FilterStar",
      this.starList.filter((ele) => ele.checked)
    );

    this.hotelFilterData.PriceFrom = this.priceArr[0];
    this.hotelFilterData.PriceTo = this.priceArr[1];
    this.$set(
      this.hotelFilterData,
      "FilterStar",
      this.starList.map((ele) => ele.checked && ele.code).filter(Boolean)
    );

    this._getHotelList();
    (this.$refs.priceStarItem as any).toggle(false);
  }

  public brandListData = [];

  public async getBrandData() {
    let { data } = await fectHotelListApi({ Action: "SearchBrand" });
    this.brandListData = JSON.parse(data.ReturnJson);
    this.brandListData.forEach((item) => {
      item.Beands.forEach((_it) => {
        let index = this.hotelFilterData["FilterBrand"].findIndex(
          (ele) => ele == _it.BrandCode
        );
        if (index > -1) {
          this.$set(_it, "Checked", true);
        }
      });
    });
  }

  public currentTypeIndex = 0;
  public getBrandName(item, index) {
    this.currentTypeIndex = index;
  }
  public getBrandCode(item) {
    this.$set(item, "Checked", !item.Checked);
    let that = this;
    that.$nextTick(() => {
      if (item.Checked) that.hotelFilterData.FilterBrand.push(item.BrandCode);
      else {
        let index = that.hotelFilterData.FilterBrand.indexOf(item.BrandCode);
        that.hotelFilterData.FilterBrand.splice(index, 1);
      }
    });
  }
  public resetBrand() {
    this.hotelFilterData.FilterBrand = [];
    this.getBrandData();
    this.handleClickBrand();
  }
  public handleClickBrand() {
    this.$set(
      this.queryHotelListCon,
      "FilterBrand",
      JSON.parse(JSON.stringify(this.hotelFilterData.FilterBrand))
    );
    this._getHotelList();
    (this.$refs.brandItem as any).toggle(false);
  }

  public toHotelDetailPage(HotelCode) {
    this.$router.push({
      path: "/hotelDetail",
      query: {
        HotelCode: HotelCode,
      },
    });
  }

  public isShowMap: boolean = false;
  handleChangeMap() {
    this.isShowMap = !this.isShowMap;
    this.isLoading = true;
    if (this.isShowMap == false) {
      this.hotelFilterData.Lon = "";
      this.hotelFilterData.Lat = "";
      this._getHotelList();
    }
  }
  public getSearchData(val) {
    for (const key in val) {
      this.hotelFilterData[key] = val[key];
    }
    this.$set(this.queryHotelListCon, "FilterCountyID", {
      code: "",
      title: "",
    });
    this.$set(this.queryHotelListCon, "FilterPosition", {
      code: "",
      title: "",
    });
    this.$set(this.hotelFilterData, "FilterCountyID", "");
    this.$set(this.hotelFilterData, "FilterPosition", "");
    this._getHotelList();
  }

  public getPageIndex(val) {
    this.hotelFilterData.PageIndex = val;
    this.getHotelList(this.hotelFilterData);
  }

  created() {
    let queryData =
      sessionStorage.getItem("queryHotelData") &&
      JSON.parse(sessionStorage.getItem("queryHotelData"));
    this.hotelData = Object.assign({ ...this.hotelData, ...queryData });
    this.getHotelList(null);
    this.getLocationItem();
    this.getBrandData();
  }
}
