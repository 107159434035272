<template>
  <van-popup
    v-model="isShow"
    :style="{ height: '100%', width: '100%', overflow: 'auto' }"
  >
    <van-nav-bar left-arrow @click-left="close" title="城市选择"></van-nav-bar>

    <div class="card">
      <van-search
        shape="round"
        @input="handleInputCity"
        @blur="clearCityName"
        @clear="clearCityName"
        v-model="cityName"
        background="#EFEFF4"
        :placeholder="`请输入国内城市名`"
      />
    </div>
    <div class="city_list_box" id="city_list_box" v-if="isShowCityList">
      <van-index-bar :index-list="getIndexList">
        <div v-for="(item, index) in cityData" :key="index">
          <van-index-anchor :index="item.letter">
            <div v-if="item.letter == '历史'" class="his_style">
              <span>{{ item.letter }}</span>
              <span class="btn_clear" @click="clearHis">清除</span>
            </div>
            <div v-else>{{ item.letter }}</div>
          </van-index-anchor>
          <div
            v-if="item.letter == '热门' || item.letter == '历史'"
            class="city-select__grid"
          >
            <div
              class="city"
              v-for="(_it, _ind) in item.data"
              :key="_ind"
              @click="handleSelectCity(_it)"
            >
              {{ _it.CityName }}
            </div>
          </div>
          <van-cell
            v-else
            :title="_it.CityName"
            v-for="(_it, _ind) in item.data"
            :key="_ind"
            @click="handleSelectCity(_it)"
          />
        </div>
      </van-index-bar>
    </div>
    <div v-else>
      <van-cell
        :title="item.Remark"
        v-for="(item, index) in queryCityList"
        :key="index"
        @click="handleSelectCity(item)"
      />
    </div>
  </van-popup>
</template>

<script>
import {
  Loading,
  Tabs,
  Tab,
  Search,
  IndexBar,
  IndexAnchor,
  Cell,
  Popup,
  NavBar,
} from "vant";
import { fectCityDataApi } from "@api/hotel/index.api";

export default {
  components: {
    [Search.name]: Search,
    [Loading.name]: Loading,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
  },
  props: {
    isShowCity: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // isShowCity(newVal) {

    //   if (newVal) {

    //   },
    // },
    isShowCity: {
      async handler(newVal) {
        this.isShow = newVal;
        if (newVal) {
          await this.getCityData();
          console.log("allCityData", this.allCityData);
          this.$nextTick(() => {
            let hisArr = [
              {
                letter: "历史",
                data: this.cityHistory,
              },
            ];
            this.cityData = [...hisArr, ...this.allCityData];
            console.log("cityData", this.cityData);
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    getIndexList() {
      return this.cityData && this.cityData.map((item) => item.letter);
    },
  },
  data() {
    return {
      isShow: false,
      cityData:
        (sessionStorage.getItem("cityListData") &&
          JSON.parse(sessionStorage.getItem("cityListData"))) ||
        [],
      allCityData: [],
      cityHistory:
        (sessionStorage.getItem("HisCity") &&
          JSON.parse(sessionStorage.getItem("HisCity"))) ||
        [],
      cityName: "",
      isShowCityList: true,
      queryCityList: [],
    };
  },

  methods: {
    async getCityData() {
      let letters = "ABCDEFGHJKLMNOPQRSTWXYZ".split("");
      let { data } = await fectCityDataApi({ Action: "GetCityData" });
      let cityData = JSON.parse(data.ReturnJson);
      let obj = {};
      let arr = [];
      letters.forEach((item) => {
        obj = {
          letter: item,
          data: [],
        };
        cityData.forEach((ele) => {
          if (ele.PinYin.slice(0, 1).toLocaleUpperCase() == item) {
            obj["data"].push(ele);
          }
        });
        if (obj["data"].length > 0) arr.push(obj);
      });
      arr.unshift({
        letter: "热门",
        data: cityData.slice(0, 20),
      });
      this.$set(this, "cityData", JSON.parse(JSON.stringify(arr)));
      this.$set(this, "allCityData", JSON.parse(JSON.stringify(arr)));

      sessionStorage.setItem("cityListData", JSON.stringify(arr));
    },

    close() {
      if (document.getElementById("city_list_box"))
        document.getElementById("city_list_box").scrollTop = 0;
      this.$emit("update:isShowCity", false);
    },

    // 去重
    duplicateRemoval(data) {
      let map = new Map();
      for (let item of data) {
        map.set(item.name, item);
      }
      data = [...map.values()];
      if (data.length > 6) data.pop();

      return data;
    },

    handleSelectCity(val) {
      // console.log(val);
      this.cityName = "";
      this.isShowCityList = true;
      if (val.CityName) val.name = val.CityName;
      if (val.code) val.CityCode = val.code;
      this.$emit("cityDateObj", val);

      this.cityHistory.unshift(val);

      this.cityHistory = this.duplicateRemoval(this.cityHistory);
      // var obj = {}
      // this.cityHistory = this.cityHistory.reduce(function (a, b) {
      //   obj[b.name] ? '' : (obj[b.name] = true && a.push(b))
      //   return a
      // }, [])
      // if (this.cityHistory.length && this.cityHistory.length > 6) {
      //   this.cityHistory.pop()
      // }
      sessionStorage.setItem("HisCity", JSON.stringify(this.cityHistory));

      this.close();
    },

    async handleInputCity() {
      this.isShowCityList = !this.cityName;
      if (
        !this.isShowCityList &&
        this.cityName.length >= 2 &&
        /[\u4e00-\u9fa5]/.test(this.cityName)
      ) {
        const city = {
          Action: "SearchCitys",
          KeyWord: this.cityName,
        };
        const { data } = await fectCityDataApi(city);
        // console.log(data)
        if (data.Status === 200) {
          this.$set(this, "queryCityList", JSON.parse(data.ReturnJson));
        }
      }
    },
    clearCityName() {
      this.cityName = "";
    },

    clearHis() {
      sessionStorage.removeItem("HisCity");
      this.cityData = this.cityData.filter((item) => item.letter != "历史");
      this.cityHistory = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.city_list_box {
  position: relative;
  overflow: auto;
  height: 85%;
}

.city-select__grid {
  .city {
    display: inline-block;
    width: 28%;
    margin: 0.21rem 0;
    padding: 10px 0;
    margin-left: 12px;
    text-align: center;
    font-size: 0.3733rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #f0f0f0;
  }
  .custom-item {
    height: 0.85rem;
    line-height: 0.85rem;
    padding: 0;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 0.11rem;
    font-size: 0.32rem;
  }
}

.his_style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn_clear {
    color: #0090ff;
  }
}
.card {
  position: relative;
  z-index: 8888;
}

/deep/.van-index-bar__index {
  padding: 0;
  line-height: 15px;
}
</style>
